.productos-lp {
  body {
    h1 {
      font-size: 2em;
      text-align: center;
      margin-top: 30%;
    }
  }
  #producto1-lp {
    margin-left: 50px;
  }
  #producto2-lp {
    position: relative;
    float: left;
    right: 180px;
    bottom: 10px;
    opacity: 0;
    z-index: 50;
  }

  #producto3-lp {
    position: relative;
    float: right;
    left: 65px;
    bottom: 10px;
    opacity: 0;
    z-index: 50;
  }

  section {
    padding: 3em;
    height: 100vh;
    position: relative;
    box-sizing: border-box;

    blockquote {
      font-size: 2.3em;
      width: 30%;
      margin-top: 17%;
      position: absolute;

      span {
        width: 100%;
        background: red;
        display: block;
        height: 5px;
        margin-top: 20px;
      }
    }

    img:nth-of-type(1) {
      width: 40%;
      right: 0;
      top: 20%;
    }
    img:nth-of-type(2) {
      width: 25%;
      right: 40%;
      top: 29%;
      margin-top: 15%;
    }
  }
  section:nth-child(odd) {
    background: #f1f1f1;
  }
  .sticky {
    background: white !important;
  }

  .percentage {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  #box {
    width: 100px;
    height: 100px;
    position: absolute;
    border: 5px solid lightgray;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.outer-left {
  position: relative;
  text-align: left;
  .inner-left {
    #left-animation {
      left: 0px;
      opacity: 0;
      margin: 20px;
    }
  }
}

.outer-right {
  position: relative;
  text-align: right;
  z-index: 1;
  .inner-right {
    position: relative;
    top: 70px;
    #producto1-lp {
      opacity: 0;
      right: 0px;
    }
  }
}

.prodcutos-section {
  #patatas-section {
    position: relative;
  }
  #cebollas-section {
    position: relative;
  }
  #exoticos-section {
    position: relative;
  }
}
