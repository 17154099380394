.v-header {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  color: #fff;
}

.container {
  max-width: 960px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.fullscreen-video-wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -5;
}

.fullscreen-video-wrap video {
  position: relative;
  height: auto;
  width: auto;
  min-width: 100%;
  display: block;
  background-size: cover;
  transform: translateX(-10%) translateY(-5%);
}

.header-overlay {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000;
  opacity: 0.5;
  z-index: -3;
}
