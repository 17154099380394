/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

@import "elaborats-prepirineu/variables";
@import "elaborats-prepirineu/mixins";

// Plugins CSS
@import "elaborats-prepirineu/plugins/plugin-bootstrap-switch";
@import "elaborats-prepirineu/plugins/plugin-nouislider";
@import "elaborats-prepirineu/plugins/plugin-datepicker";

// Core CSS
@import "elaborats-prepirineu/buttons";
@import "elaborats-prepirineu/inputs";
@import "elaborats-prepirineu/checkbox-radio";
@import "elaborats-prepirineu/progress-bars";
@import "elaborats-prepirineu/badges";
@import "elaborats-prepirineu/pagination";
@import "elaborats-prepirineu/typography";
@import "elaborats-prepirineu/misc";
@import "elaborats-prepirineu/labels";

// components
@import "elaborats-prepirineu/nucleo-icons";
@import "elaborats-prepirineu/social-buttons";
@import "elaborats-prepirineu/tabs-navs";
@import "elaborats-prepirineu/navbars";
@import "elaborats-prepirineu/dropdown";
@import "elaborats-prepirineu/alerts";
@import "elaborats-prepirineu/images";
@import "elaborats-prepirineu/tooltips-and-popovers";
@import "elaborats-prepirineu/modal";
@import "elaborats-prepirineu/icons";
@import "elaborats-prepirineu/carousel";
@import "elaborats-prepirineu/footers";
@import "elaborats-prepirineu/videos";
@import "elaborats-prepirineu/timeline";
@import "elaborats-prepirineu/maps";
@import "elaborats-prepirineu/productos";
@import "elaborats-prepirineu/landing";

// Cards
@import "elaborats-prepirineu/cards";
@import "elaborats-prepirineu/cards/card-plain";
@import "elaborats-prepirineu/cards/card-register";
@import "elaborats-prepirineu/cards/card-profile";

// example pages and sections
@import "elaborats-prepirineu/examples";
@import "elaborats-prepirineu/sections";

@import "elaborats-prepirineu/responsive";

// React Differences
@import "react/react-differences.scss";
